import React from 'react';

interface imgProps {
    url: string,
    title?: string;
    alt?: string;
  }

function Logo ({url, title, alt}: imgProps): JSX.Element {
    return (
        <div className="size-14 lg:size-20 logo__wrapper">
            <span className="inline-block size-full rounded-full overflow-hidden logo__container">
                <img className="aspect-square logo__image" src={url} title={title ? title : 'Logo image'} alt={alt ? alt : 'Logo image'} />
            </span> 
        </div>
    )
}

export default Logo;