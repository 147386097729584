import React from 'react';


function SiteTitle ({ title, subTitle }: { title: string, subTitle: string }): JSX.Element {
    return (
        <span className="text-white ml-4 lg:ml-6 site-title">
            <h1 className="font-title text-site-title lg:text-site-title-lg site-title__title">
                {title}
            </h1> 
            <h2 className="font-subtitle text-site-subtitle lg:text-site-subtitle-lg italic site-title__subtitle">
                {subTitle}
            </h2>
        </span>
    )
}

export default SiteTitle;