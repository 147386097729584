import React from 'react';
import Wip from './pages/Wip'
import properties from './assets/properties.json';
import standardTitleicon from './assets/icons/dita-pizzicate.png';
import helperFunctions from './helpers';

import './App.scss';


function App() {
  return (
    <div className="App">
      <Wip />
    </div>
  );
}

export default App;
