import React from 'react';
import logo from '../assets/images/Alessandro.jpg';
import Logo from '../components/Logo';
import SiteTitle from '../components/SiteTitle';
import Container from '../components/Container';
import properties from '../assets/properties.json';

export default function Wip(): JSX.Element {
    return (
        <header className="site-header__container">
            <Container classes="flex flex-col items-center h-dvh justify-center text-center gap-4">
                <Logo url={logo} alt={properties.logoAlt} title={properties.logoTitle} />
                <SiteTitle title={properties.siteTitle} subTitle={properties.siteSubTitle} />
                <p>- site under construction -</p>
                <strong><a href="https://www.linkedin.com/in/alessandrolongodev">My Linkedin</a></strong>
            </Container>
        </header>
    )
}